import { nodeInstance } from "@/plugins/axios";

const followService = {};

followService.getMyFollowers = obj => {
  const params = {
    take: obj.take,
    skip: obj.skip
  };
  return nodeInstance.get(`/api/follower/users`, { params });
};

followService.followUser = friendId => {
  return nodeInstance.post(`/api/follow/user/${friendId}`);
};

followService.unFollowUser = friendId => {
  return nodeInstance.post(`/api/unfollow/user/${friendId}`);
};

export default followService;