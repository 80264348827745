<template src="./Dashboard.html"></template>

<script>
import { mapGetters } from "vuex";
import ArrobaMedellinAppBar from "@/components/ArrobaMedellinAppBar/ArrobaMedellinAppBar.vue";
import ArrobaMedellinFooter from "@/components/ArrobaMedellinFooter/ArrobaMedellinFooter.vue";
import ArrobaMedellinArrobitaLoading from "@/components/ArrobaMedellinArrobitaLoading/ArrobaMedellinArrobitaLoading";
import SideMenuAccessibility from "@/components/SideMenuAccessibility/SideMenuAccessibility";
import profileService from "@/services/Profile";

export default {
  name: "dashboard",
  components: {
    ArrobaMedellinAppBar,
    ArrobaMedellinFooter,
    ArrobaMedellinArrobitaLoading,
    SideMenuAccessibility
  },
  data() {
    return {
      active: false
    }
  },
  mounted() {
    if (!this.user && window.location.pathname !== "/login") {
      this.reloadUser();
    }
    window.document.onscroll = () => {
      if (window.scrollY > 10) {
        this.active = true;
      } else {
        this.active = false;
      }
    }
  },
  methods: {
    async reloadUser() {
      try {
        const user = await profileService.getUserInfo();
        this.$store.dispatch("fetchUser", user);
      } catch (error) {
        console.warn("No sesion for User");
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    hasFooter() {
      return this.$route.meta.noFooter;
    },
  },
};
</script>

<style lang="scss" scoped src="./Dashboard.scss">
</style>
