<template src="./Notifications.html"></template>

<script>
import { mapGetters } from "vuex";
import { districtInfo } from "@/utils/constants";
import NotificationIcon from "@/components/Icons/NotificationIcon";
import NotificationIconActive from "@/components/Icons/NotificationIconActive";
import NotificationCard from "@/components/NotificationCard/NotificationCard";

export default {
  name: "notifications",
  components: {
    NotificationIcon,
    NotificationIconActive,
    NotificationCard,
  },
  props: ["user"],
  data() {
    return {
      notificationSelected: false,
    };
  },
  beforeMount() {
    if (this.user) {
      this.$store.dispatch("initNotifications", this.user.id);
    }
  },
  computed: {
    ...mapGetters({
      notifications: "getNotifications",
    }),
    getDistrictInfo() {
      return (district) => districtInfo.find((item) => item.name == district);
    },
  },
  methods: {
    activeNotifications() {
      this.notificationSelected = !this.notificationSelected;
      if (screen.width < 480) {
        this.redirectTo("notificaciones");
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./Notifications.scss"></style>
