export default {
  state: {
    institutionSelected: null,
    DistrictSelected: null,
    mutePage: false,
    modalQualification: {
      show: false,
      data: null
    },
    modalWatchAndRate: {
      show: false,
      data: null
    },
    modalShareAndLoginEvent: {
      show: false,
      data: null
    }
  },
  actions: {
    async fetchInstitutionSelected({ commit }, data) {
      try {
        commit("setInstitutionSelected", data);
      } catch (e) {
        console.error("error", e);
      }
    },
    async fetchDistrictSelected({ commit }, data) {
      try {
        commit("setDistrictSelected", data);
      } catch (e) {
        console.error("error", e);
      }
    },
    fetchQualification({ commit }, qualification) {
      commit('setQualification', qualification);
    },
    fetchWatchAndRate({ commit }, watchAndRate) {
      commit('setWatchAndRate', watchAndRate);
    },
    fetchShareAndLoginEvent({ commit }, shareAndLoginEvent) {
      commit('setShareAndLoginEvent', shareAndLoginEvent);
    },
    fetchMutePage({ commit }, mute) {
      commit('setMutePage', mute);
    }
  },
  mutations: {
    setInstitutionSelected(state, value) {
      state.institutionSelected = value;
    },
    setDistrictSelected(state, value) {
      state.DistrictSelected = value;
    },
    setQualification(state, value) {
      state.modalQualification = value;
    },
    setWatchAndRate(state, value) {
      state.modalWatchAndRate = value;
    },
    setShareAndLoginEvent(state, value) {
      state.modalShareAndLoginEvent = value;
    },
    setMutePage(state, value) {
      state.mutePage = value;
    },
  },
  getters: {
    getInstitutionSelected: state => state.institutionSelected,
    getDistrictSelected: state => state.DistrictSelected,
    getModalQualification: state => state.modalQualification,
    getModalWatchAndRate: state => state.modalWatchAndRate,
    getModalShareAndLoginEvent: state => state.modalShareAndLoginEvent,
    getMutPage: state => state.mutePage,
  }
};
