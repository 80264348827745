
export default {
  state: {
    notice: false,
  },
  actions: {
    async fetchNotice({ commit}, data) {
      try {
        commit("setNotice", data);
      } catch (e) {
        console.error("error", e);
      }
    }
  },
  mutations: {
    setNotice(state, value) {
      state.notice = value;
    },
  },
  getters: {
    getNotice: state => state.notice,
  }
};
