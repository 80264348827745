<template src="./ArrobaMedellinArrobitaLoading.html"></template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "arroba-medellin-arrobita-loading",
  computed: {
    ...mapGetters({
      loading: "getLoading",
    }),
  },
};
</script>

<style lang="scss" scoped src='./ArrobaMedellinArrobitaLoading.scss'>
</style>