import { db } from '@/plugins/firebase';
const parametersDb = db.collection("parameters");

export default {
    state: {
        activateTutorial: true,
    },
    getters: {
        getStatusTutorial: state => state.activateTutorial,
    },
    mutations: {
        changeStatusTutorial(state, status) {
            state.activateTutorial = status;
        },
    },
    actions: {
        initTutorial({ commit }) {
            parametersDb.doc("tutorial")
                .onSnapshot((doc) => {
                    const parameters = doc.data();
                    if (parameters)
                        commit("changeStatusTutorial", parameters.activateTutorial);
                });
        },
    },
}