<template src="./Chat.html"></template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { PROFILE_IMAGEN } from "@/utils/constants";
import ChatIcon from "@/components/Icons/ChatIcon";
import { db, timestamp } from "@/plugins/firebase";
const chatDb = db.collection("chats");
const messagesDb = db.collection("messages");
let unsubscribe;

export default {
  name: "Chat",
  components: {
    ChatIcon
  },
  data() {
    return {
      PROFILE_IMAGEN,
      isChat: false,
      searchChat: "",
      searchFollows: [],
      chats: [],
      messages: [],
      message: "",
      userToChat: null,
      countChats: 0
    };
  },
  mounted() {
    if (this.user) {
      this.getChats();
    }
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      follows: "getFollows"
    }),
    filterFollows() {
      try {
        if (this.searchChat.length) {
          return this.follows.filter(user => {
            return (
              `${user.name} ${user.lastName}`
                .toLowerCase()
                .includes(this.searchChat) ||
              user.email.toLowerCase().includes(this.searchChat)
            );
          });
        }
        return this.follows;
      } catch (error) {
        return this.follows;
      }
    }
  },
  methods: {
    async createChat(user) {
      try {
        let chat;
        const members = [this.user.id, user.id];
        const usersId = members.sort().join("-");

        const exist = await chatDb.where("usersId", "==", usersId).get();
        if (exist.empty) {
          chat = await chatDb.add({
            members,
            users: [
              { user: this.user.id, read: true },
              { user: user.id, read: false }
            ],
            date: timestamp.now(),
            usersId
          });
        } else {
          exist.forEach(doc => {
            chat = { id: doc.id, ...doc.data() };
          });
        }

        this.showChat({ ...user, chatId: chat.id });
      } catch (error) {
        console.error("Error on create chat", error);
      }
    },
    getChats() {
      try {
        chatDb
          .where("members", "array-contains", this.user.id)
          .where(
            "date",
            ">",
            moment()
              .subtract(1, "month")
              .toDate()
          )
          .limit(20)
          .onSnapshot(snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === "modified") {
                const data = change.doc.data();
                let count = 0;
                data.users.forEach(memberUser => {
                  if (
                    memberUser.user === this.user.id &&
                    memberUser.read === false
                  ) {
                    count++;
                  }
                });
                this.countChats = count;
              }
              if (change.type === "added") {
                const data = change.doc.data();
                // Get the user who is not me in chat
                const userChatId = data.members.find(
                  member => member !== this.user.id
                );
                // Search for the user in my followers to get all the info.
                const user = this.follows.find(
                  follow => follow.id === userChatId
                );
                if (user) {
                  this.chats.push({
                    chatId: change.doc.id,
                    ...user,
                    date: data.date,
                    usersChat: data.users
                  });
                  let count = 0;
                  this.chats.forEach(chat => {
                    chat.usersChat.forEach(memberUser => {
                      if (
                        memberUser.user === this.user.id &&
                        memberUser.read === false
                      ) {
                        count++;
                      }
                    });
                  });
                  this.countChats = count;
                }
              }
              if (change.type === "removed") {
                this.chats = this.chats.filter(
                  chat => chat.id !== change.doc.id
                );
              }
            });
          });
      } catch (error) {
        console.error("Error on loading chats", error);
      }
    },
    async showChat(chat) {
      this.isChat = true;
      this.userToChat = chat;
      this.getMessages();
      const chatSelectedCollectionSelected = chatDb.doc(chat.chatId);
      if (chat.usersChat) {
        chat.usersChat.forEach(membersUsers => {
          if (membersUsers.user === this.user.id) {
            membersUsers.read = true;
          }
          return membersUsers;
        });
        chatSelectedCollectionSelected.update({ users: chat.usersChat });
      }
    },
    async hideChat() {
      this.isChat = false;
      this.userToChat = null;
      this.messages = [];
      this.message = "";
      await unsubscribe();
    },
    getMessages() {
      try {
        unsubscribe = messagesDb
          .where("chatId", "==", this.userToChat.chatId)
          .orderBy("createdAt", "desc")
          .limit(5) // Number of messages
          .onSnapshot(snapshot => {
            const messages = snapshot.docChanges();
            messages.forEach(change => {
              if (change.type === "added") {
                if (
                  messages.length === 0 ||
                  messages.length === 1 ||
                  messages.length === 2
                ) {
                  this.messages.push({
                    id: change.doc.id,
                    ...change.doc.data()
                  });
                } else {
                  this.messages.unshift({
                    id: change.doc.id,
                    ...change.doc.data()
                  });
                }
              }
            });
          });
      } catch (error) {
        console.error("Error on get chat messages", error);
      }
    },
    async sendMessage() {
      try {
        if (this.message.length) {
          await messagesDb.add({
            chatId: this.userToChat.chatId,
            createdAt: timestamp.now(),
            from: this.user.id,
            to: this.userToChat.id,
            message: this.message
          });

          const chatSelectedCollectionSelected = chatDb.doc(
            this.userToChat.chatId
          );
          this.userToChat.usersChat.forEach(membersUsers => {
            if (membersUsers.user !== this.user.id) {
              membersUsers.read = false;
            }
            return membersUsers;
          });
          chatSelectedCollectionSelected.update({
            users: this.userToChat.usersChat
          });
          this.message = "";
        }
      } catch (error) {
        console.error("Error on sending the message", error);
      }
    },
    clearMessages() {
      this.countChats = 0;
    }
  }
};
</script>

<style lang="scss" scoped src="./Chat.scss"></style>
