import jobsService from "@/services/Jobs";

export default {
  state: {
    jobApplicationPhases: null,
    cv: null,
  },
  getters: {
    getJobApplicationPhases: state => state.jobApplicationPhases,
    getCv: state => state.cv,
  },
  mutations: {
    setJobApplicationPhases(state, value) {
      state.jobApplicationPhases = value;
    },
    setCv(state, value) {
      state.cv = value;
    },
  },
  actions: {
    async fetchJobApplicationPhases({ commit }) {
      try {
        const data = await jobsService.getJobApplicationPhases();
        commit('setJobApplicationPhases', data);
      } catch (e) {
        console.error('error', e);
      }
    },
    async fetchCv({ commit }, obj) {
      try {
        commit("setCv", obj);
      } catch (e) {
        console.error("error", e);
      }
    }
  }
};
