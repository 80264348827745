<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65.176"
    height="65.177"
    viewBox="0 0 65.176 65.177"
    v-on="$listeners"
  >
    <defs>
      <clipPath id="prefix__a">
        <path
          data-name="Trazado 2391"
          d="M22.472-22a1.605 1.605 0 0 1 1.605 1.605v1.605h3.21a4.816 4.816 0 0 1 4.813 4.816V8.5a4.816 4.816 0 0 1-4.816 4.816H4.816A4.816 4.816 0 0 1 0 8.5v-22.474a4.816 4.816 0 0 1 4.816-4.816h3.21v-1.605a1.605 1.605 0 0 1 1.418-1.594L9.631-22a1.605 1.605 0 0 1 1.605 1.605v1.605h9.631v-1.605a1.605 1.605 0 0 1 1.418-1.594zm6.421 16.052H3.21V8.5a1.605 1.605 0 0 0 1.606 1.6h22.472a1.605 1.605 0 0 0 1.605-1.6zM8.026-15.579h-3.21a1.605 1.605 0 0 0-1.606 1.605v4.816h25.683v-4.816a1.605 1.605 0 0 0-1.605-1.605h-3.21v1.605a1.605 1.605 0 0 1-1.418 1.594l-.187.011a1.605 1.605 0 0 1-1.605-1.605v-1.605h-9.632v1.605a1.605 1.605 0 0 1-1.418 1.594l-.187.011a1.605 1.605 0 0 1-1.605-1.605z"
          transform="translate(0 22)"
          fill="#ae3e97"
        ></path>
      </clipPath>
      <clipPath id="prefix__b">
        <path
          data-name="Trazado 2390"
          d="M-1292 902.616h2956.734V-343H-1292z"
          transform="translate(1292 343)"
          fill="#ae3e97"
        ></path>
      </clipPath>
      <clipPath id="prefix__c">
        <path
          data-name="RectÃ¡ngulo 1357"
          fill="#ae3e97"
          d="M0 0h48.885v52.005H0z"
        ></path>
      </clipPath>
      <clipPath id="prefix__d">
        <path
          data-name="Trazado 2389"
          d="M0 13.314h32.1V-22H0z"
          transform="translate(0 22)"
          fill="#ae3e97"
        ></path>
      </clipPath>
    </defs>
    <path
      data-name="Trazado 149"
      d="M32.588 65.177a32.588 32.588 0 0 0 32.588-32.588A32.588 32.588 0 0 0 32.588 0 32.588 32.588 0 0 0 0 32.588a32.588 32.588 0 0 0 32.588 32.589z"
      fill="#fff"
      fill-rule="evenodd"
    ></path>
    <g data-name="Grupo 2492">
      <g
        data-name="Grupo 2491"
        transform="translate(17 15)"
        clip-path="url(#prefix__a)"
      >
        <g
          data-name="Grupo 2490"
          transform="translate(-2073.887 -515.261)"
          clip-path="url(#prefix__b)"
        >
          <g data-name="Grupo 2489">
            <g
              data-name="Grupo 2488"
              transform="translate(2065.566 506.891)"
              clip-path="url(#prefix__c)"
            >
              <g
                data-name="Grupo 2487"
                transform="translate(8.321 8.371)"
                clip-path="url(#prefix__d)"
              >
                <path
                  data-name="Trazado 2388"
                  d="M-8.026-8.026h48.155V43.34H-8.026z"
                  fill="#ae3e97"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'agenda'
}
</script>
