import followService from "@/services/Follow";

export default {
  state: {
    follows: [],
  },
  getters: {
    getFollows: state => state.follows,
  },
  mutations: {
    setFollows(state, value) {
      state.follows = value;
    },
  },
  actions: {
    async fetchFollows({ commit }, obj) {
      try {
        const data = await followService.getMyFollowers(obj);
        commit("setFollows", data);
      } catch (e) {
        console.error("error", e);
      }
    }
  }
};
