import { phpInstance, nodeInstance } from "@/plugins/axios";
import axios from 'axios';
import configService from "../../configs/config.js";


const loginService = {};

loginService.login = user => {
  return nodeInstance.post(`/api/user/login`, user);
};

loginService.auth = user => {
  return nodeInstance.post(`/api/user/auth`, user);
};

loginService.getUserAuthByRol = data => {
  return nodeInstance.post(`/api/user/authByRol`, data);
};

loginService.passwordRecovery = user => {
  return phpInstance.post(`/user/password/recover`, user);
}

loginService.sendNewPassword = data => {
  return axios.post(`${configService.apiUrl}/user/password/change`, data);
}

loginService.sendNewPasswordV2 = data => {
  return phpInstance.post(`/user/profile/changePassword`, data);
}

loginService.logout = () => {
  return phpInstance.get(`/logout`);
};

loginService.getEmailsUsers = document => {
  return phpInstance.get(`/user/getEmailsUsersByDocument/${document}`);
};

loginService.getExistingAccount = document => {
  return phpInstance.get(`/validateExistUser/${document}`);
};

loginService.recoverPassword = data => {
  return phpInstance.post(`/user/password/changeUserController`, data);
};

loginService.passwordRecoveryUserController = (data, tokenPHP = null) => {
  const temporalToken = phpInstance.defaults.headers.common[
    "Authorization"
  ];
  if (tokenPHP) {
    phpInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokenPHP}`;
  }
  const dataResponse = phpInstance.post(`/user/profile/changePasswordUserController`, data);
  if (tokenPHP) {
    phpInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${temporalToken}`;
  }
  return dataResponse;
};

loginService.getEmailsUsersByDocument = document => {
  return phpInstance.get(`/user/getEmailsUsersByDocument/${document}`);
};

loginService.notifyChangePasswordUserController = data => {
  return phpInstance.post(`/user/profile/notifyChangePasswordUserController`, data);
};

loginService.getEmailsUsers = document => {
  return phpInstance.get(`/user/getEmailsUsersByDocument/${document}`);
};

loginService.sendRecoverPassword = data => {
  return phpInstance.post(`/user/password/recoverPasswordUserController`, data);
}

export default loginService;
