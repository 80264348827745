import { db } from '@/plugins/firebase';
import { songs } from "@/utils/constants";
const notificationsDb = db.collection("notifications");

export default {
  state: {
    notifications: [],
  },
  getters: {
    getNotifications: state => state.notifications,
  },
  mutations: {
    emptyNotifications(state) {
      state.notifications = [];
    },
    addedNotifications(state, value) {
      state.notifications.unshift(value);
    },
    modifiedNotifications(state, value) {
      state.notifications = state.notifications.map(notification => {
        if (notification.id === value.id) {
          notification = value;
        }
        return notification;
      });
    },
    removeNotifications(state, value) {
      state.notifications = state.notifications.filter(notification => notification.id !== value.id);
    },
  },
  actions: {
    initNotifications({ commit }, userId) {
      commit('emptyNotifications');

      notificationsDb
        .where('userId', '==', userId)
        .orderBy("createdAt", "desc")
        .limit(10)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case 'added':
                songs.NOTIFICATION.play();
                commit('addedNotifications', {
                  ...change.doc.data(),
                  id: change.doc.id,
                });
                break;
              case 'modified':
                commit('modifiedNotifications', {
                  ...change.doc.data(),
                  id: change.doc.id,
                });
                break;
              case 'removed':
                commit('removeNotifications', {
                  ...change.doc.data(),
                  id: change.doc.id,
                });
                break;
            }
          });
        });
    }
  }
};
