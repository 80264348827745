const USER_DOES_NOT_EXIST = "Usuario no existe";
const INCORRECTS_USER_OR_PASSWORD = "Usuario o contraseña incorrectos";
const EMAIL_SENT_SUCCESSFYLLY = "Correo enviado exitosamente";
const SUCCESS_CHANGE_PASSWORD = "Nueva contraseña establecida con éxito.";
const EROR_IN_CHANGE = "Error en la actualización de la contraseña, vuelve a intentarlo.";
const ERROR_SEARCH = "NO se encontró ningun curso en la busqueda";

const PROFILE_IMAGEN = "https://storage.googleapis.com/arrobamedellin.appspot.com/default.png";

const recoverPasswordConstants = Object.freeze({
  USER_DOES_NOT_EXIST,
  EMAIL_SENT_SUCCESSFYLLY,
  SUCCESS_CHANGE_PASSWORD,
  EROR_IN_CHANGE,
  INCORRECTS_USER_OR_PASSWORD
});

const apiConsumptionConstants = Object.freeze({
  ERROR_SEARCH
})

const roleName = Object.freeze({
  STUDENT: 'Student',
  MANAGEMENT: 'Management',
  ADMINISTRATOR: 'Administrator',
  UNIVERSITY: 'University',
  ADMINISTRATOR_CALL: 'AdministratorCall',
  MANAGEMENT_IES: 'ManagementIES',
  TEACHER: 'Teacher',
  ENTREPRENEUR: 'Entrepreneur',
  INVITED: 'Invited',
  TEACHER_INVESTIGATOR: 'TeacherInvestigator'
})

const districtInfo = Object.freeze([{
    name: "Académico",
    image: require("../assets/img/Profile/Academico.png"),
    route: "/academico"
  },
  {
    name: "Bolsa de trabajo",
    image: require("../assets/img/Profile/Trabajo.png"),
    route: "/empleo"
  },
  {
    name: "Entretenimiento",
    image: require("../assets/img/Profile/Comercial.png"),
    route: "/entretenimiento"
  },
  {
    name: "Eventos",
    image: require("../assets/img/Profile/Eventos.png"),
    route: "/eventos"
  },
  {
    name: "General",
    image: require("../assets/img/Icono_notificaciones_generales.png"),
    route: "/"
  },
  {
    name: "I+D+I",
    image: require("../assets/img/Profile/IDI.png"),
    route: "/idi"
  },
  {
    name: "Networking",
    image: require("../assets/img/Profile/Networking.png"),
    route: "/networking"
  },
  {
    name: "Perfil",
    image: require("../assets/img/Profile/Perfil.png"),
    route: "/perfil"
  },
])

const showTutorial = 2;

const songs = Object.freeze({
  COMMERCIAL: new Audio(require("../assets/songs/A_MX_Avatar Design_Loop.ogg")),
  ACESS_OK: new Audio(require("../assets/songs/Acess_Ok.ogg")),
  ALERT: new Audio(require("../assets/songs/Alert.ogg")),
  CLOSE: new Audio(require("../assets/songs/Close_1.ogg")),
  NOTIFICATION: new Audio(require("../assets/songs/Notification.ogg")),
  SELECT: new Audio(require("../assets/songs/Select.ogg"))
});

module.exports = {
  recoverPasswordConstants,
  apiConsumptionConstants,
  roleName,
  districtInfo,
  PROFILE_IMAGEN,
  showTutorial,
  songs,
}
