
export default {
  state: {
    ovi: [],
  },
  getters: {
    getOvi: state => state.ovi,
  },
  mutations: {
    setOvi(state, value) {
      state.ovi = value;
    },
  },
  actions: {
    async fetchOvi({ commit }, obj) {
      try {
        commit("setOvi", obj);
      } catch (e) {
        console.error("error", e);
      }
    }
  }
};
