<template src="./NoLogin.html">
</template>

<script>
import ArrobaMedellinLogo from '@/components/Icons/ArrobaMedellinLogo';
export default {
  components: { ArrobaMedellinLogo },
  computed: {
    terms() {
      return this.$route.meta.isUseTerms;
    }
  }
}
</script>

<style lang="scss" scoped src="./NoLogin.scss">

</style>