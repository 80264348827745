import { nodeInstance, phpInstance } from "@/plugins/axios";

const userService = {};

userService.searchUser = word => {
  return nodeInstance.get(`/api/user/search`, { params: { word } });
};

userService.getOtherUser = id => {
  return nodeInstance.get(`/api/user/${id}`);
};

userService.searchUsersWithDiferentRole = (roleOption, word) => {
  return nodeInstance.get(`/api/user/search/diferent/${roleOption}`, { params: { word } });
};

userService.registerUserCampus = dataUser => {
  delete phpInstance.defaults.headers.common["Authorization"];
  return phpInstance.post(`/user/createUserInvited`, dataUser);
};

userService.sendRegistrationConfirmation = (userId) => {
  return phpInstance.get(`/user/sendConfirmRegister/${userId}`);
};

export default userService;
