<template src="./ArrobaMedellinCalendar.html"></template>

<script>
import AgendaIcon from "@/components/Icons/Agenda";

export default {
  name: "arroba-medellin-calendar",
  components: { AgendaIcon },
  data() {
    return {
      dialogConfig: {
        createButtonLabel: "Crear",
        title: "Crear Evento",
        fields: [
          {
            name: "name",
            label: "Nombre del evento",
          },
          {
            name: "comments",
            type: "textarea",
            label: "Comentarios",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped src="./ArrobaMedellinCalendar.scss">
</style>
