import {
  nodeInstance
} from "@/plugins/axios";

const calendarService = {};

calendarService.createCalendarEvent = event => nodeInstance.post('/api/calendar', event);
calendarService.getEventCalendarUser = (startDate, endDate) => nodeInstance.get('/api/calendar', {
  params: {
    startDate,
    endDate
  }
});

export default calendarService;
