<template src="./NotificationCard.html"></template>
<script>
import notificationService from "@/services/Notifications";

export default {
  name: "notification-card",
  props: {
    title: String,
    body: String,
    district: String,
    image: String,
    route: String,
    id: String,
  },
  methods: {
    async readAndRedirect(id) {
      await notificationService.removeNotification(id);
      this.$router.push({ path: `${this.route}` }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped src="./NotificationCard.scss"></style>
