<template src="./SideMenuAccessibility.html"></template>

<script>
export default {
  name: "SideMenuAccessibility",
  data() {
    return {
      overlay: false,
      menuVisible: false,
    };
  },
  methods: {
    changesFont(ind) {
      var sizeTags = [];
      var h1s = document.getElementsByTagName("h1");
      var h2s = document.getElementsByTagName("h2");
      var h3s = document.getElementsByTagName("h3");
      var h4s = document.getElementsByTagName("h4");
      var h5s = document.getElementsByTagName("h5");
      var h6s = document.getElementsByTagName("h6");
      var as = document.getElementsByTagName("a");
      var ps = document.getElementsByTagName("p");
      var buttons = document.getElementsByTagName("button");
      var spans = document.getElementsByTagName("span");

      h1s.forEach((element) => {
        sizeTags.push(element);
      });
      h2s.forEach((element) => {
        sizeTags.push(element);
      });
      h3s.forEach((element) => {
        sizeTags.push(element);
      });
      h4s.forEach((element) => {
        sizeTags.push(element);
      });
      h5s.forEach((element) => {
        sizeTags.push(element);
      });
      h6s.forEach((element) => {
        sizeTags.push(element);
      });
      as.forEach((element) => {
        sizeTags.push(element);
      });
      ps.forEach((element) => {
        sizeTags.push(element);
      });
      buttons.forEach((element) => {
        sizeTags.push(element);
      });
      spans.forEach((element) => {
        sizeTags.push(element);
      });
      for (let title in sizeTags) {
        var fontSizeNew;
        var fontSize = parseFloat(
          window
            .getComputedStyle(sizeTags[title], null)
            .getPropertyValue("font-size")
        );
        if (ind === "rest") {
          fontSizeNew = fontSize - 1;
          sizeTags[title].style.fontSize = fontSizeNew.toString().concat("px");
        } else if (ind === "subr") {
          var txtDecoration = window
            .getComputedStyle(sizeTags[title], null)
            .getPropertyValue("text-decoration-line");
          if (txtDecoration === "none") {
            sizeTags[title].style.textDecoration = "underline";
          } else {
            sizeTags[title].style.textDecoration = "none";
          }
        } else {
          fontSizeNew = fontSize + 1;
          sizeTags[title].style.fontSize = fontSizeNew.toString().concat("px");
        }
      }
    },
    grayscale() {
      var dashboard = document.getElementById("grayscale");
      dashboard.style.filter = "grayscale()";
    },
    grayscaleOff() {
      var dashboard = document.getElementById("grayscale");
      dashboard.style.filter = "none";
      dashboard.style.backgroundColor = "white";
    },
    contrast() {
      var dashboard = document.getElementById("grayscale");
      dashboard.style.backgroundColor = "black";
    },
  },
};
</script>

<style lang="scss" scoped src="./SideMenuAccessibility.scss"></style>