import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routerOptions = [
  {
    path: "/login",
    name: "Login",
    meta: {
      isPublic: true,
    }
  },
  {
    path: "/recuperar",
    name: "PasswordRecovery",
    meta: {
      isPublic: true,
      // authSetting: true
    }
  },
  {
    path: "/nuevousuario",
    name: "FirstAdmissionA",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/nuevousuario2",
    name: "FirstAdmissionB",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/nuevousuario3",
    name: "FirstAdmissionC",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/recuperatida",
    name: "RecoveryAtIdA",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/recuperatidb",
    name: "RecoveryAtIdB",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/recuperatidc",
    name: "RecoveryAtIdC",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/recuperatidd",
    name: "RecoveryAtIdD",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/recuperatide",
    name: "RecoveryAtIdE",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/recuperatiderror",
    name: "RecoveryAtIdError",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/recuperatidexito",
    name: "RecoveryAtIdSuccess",
    meta: {
      isPublic: true,
      authSetting: true
    }
  },
  {
    path: "/user/password/recover/validate/:token",
    name: "NewPassword",
    meta: {
      isPublic: true,
      authSetting: false
    }
  },
  {
    path: "/politicas",
    name: "PrivacyPolicies",
    meta: {
      isPublic: true,
      authSetting: true,
      isUseTerms: true
    }
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      isPublic: false,
      isAdmin: false,
      noFooter: true
    }
  },
  {
    path: "/",
    name: "Home3D",
    meta: {
      isPublic: false,
      isAdmin: false,
      noFooter: true
    }
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  },
  {
    path: "/perfil",
    name: "Profile",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/perfil/follow/:id",
    name: "ProfileUsers",
    meta: {
      isPublic: false
    }
  },
  {
    path: "/perfil/editar",
    name: "EditProfile",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/networking",
    name: "Networking",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/networking/meet/:idNetworking",
    name: "Meet",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/academico",
    name: "Academic",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/cursos",
    name: "AcademicAllCourses",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/empleo",
    name: "Job",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/crearOferta",
    name: "NewOffer",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/recomendaciones/:id",
    name: "Recommendations",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verCertificados",
    name: "ViewAllCertificatesProfile",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verOferta/:id",
    name: "ViewOffer",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verTodos",
    name: "ViewAll",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  }, {
    path: "/ovisAdmin",
    name: "ovisAdmin",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  }, {
    path: "/newOvi",
    name: "NewOvi",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verTodosCursosEducacionContinua",
    name: "ViewAllCourseEducationContinua",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/ViewAllMyCourses",
    name: "ViewAllMyCourses",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/AdminPanelAcademic",
    name: "AdminPanelAcademic",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/NewCV",
    name: "NewCV",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/SimpleCV2",
    name: "SimpleCV2",
    meta: {
      isPublic: true,
      isAdmin: false
    }
  }, {
    path: "/SimpleCV",
    name: "SimpleCV",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  }, {
    path: "/AcademicAllCoursesUniversity",
    name: "AcademicAllCoursesUniversity",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },{
    path: "/certificados-curso-universidad",
    name: "AcademicAllCoursesUniversityTemplate",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  }, {
    path: "/AcademicRequest",
    name: "AcademicRequest",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/NewRecommendedCourse",
    name: "NewRecommendedCourse",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  },
  {
    path: "/EditRecommendedCourse",
    name: "EditRecommendedCourse",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/NewAdvertising",
    name: "NewAdvertising",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  }, {
    path: "/EditAdvertising/:id",
    name: "EditAdvertising",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/RecommendedCourses",
    name: "RecommendedCourses",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/NewFormCourse",
    name: "NewFormCourse",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  },
  {
    path: "/EditFormCourse",
    name: "EditFormCourse",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  },
  {
    path: "/FormCourseFilled",
    name: "FormCourseFilled",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/AllFormsAcademic",
    name: "AllFormsAcademic",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/certificateTemplate",
    name: "CertificateTemplate",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/RecommendedCourses",
    name: "RecommendedCourses",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/NewFormCourse",
    name: "NewFormCourse",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  }, {
    path: "/AllFormsAcademic",
    name: "AllFormsAcademic",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  },
  {
    path: "/verTodasRecomendaciones",
    name: "ViewAllRecommendations",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/cursosRecomendados",
    name: "ViewAllRecomendedCourses",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/certificadoSeleccionado",
    name: "CertificateSelect",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verProceso/:id",
    name: "NomineeProcess",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/editarOferta/:id",
    name: "EditOffer",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/postulados/:id/:name",
    name: "AllNominees",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/idi",
    name: "Idi",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  },
  {
    path: "/idi/:id",
    name: "ResearchGroupInfo",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/idi/meet/:idEvent",
    name: "IdiMeet",
    meta: {
      isPublic: false
    }
  },
  {
    path: "/idi/info/:id",
    name: "ResearchGroup",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/eventos",
    name: "Events",
    meta: {
      isPublic: false,
      isAdmin: true
    }
  },
  {
    path: "/eventos/meet/:idEvent",
    name: "Event",
    meta: {
      isPublic: false
    }
  },
  {
    path: "/allevents",
    name: "AcademicAllCourses",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/viewAllAdvertising",
    name: "ViewAllAdvertising",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/entretenimiento",
    name: "Commercial",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/entretenimiento/shop",
    name: "ComercialStore",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/notificaciones",
    name: "AllNotifications",
    meta: {
      isPublic: false
    }
  },
  {
    path: "/Calendar",
    name: "Calendar",
    meta: {
      isPublic: false
    }
  },
  {
    path: "/ovi/:id",
    name: "Ovi",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/oviAdmin",
    name: "OviAdministrator",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/automaticCourseRegistration/:id",
    name: "AutomaticCourseRegistration",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  }, {
    path: "/courseRegistration/:courseId",
    name: "CourseRegistration",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/createCV",
    name: "CreateCV",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/changePasswordUserController",
    name: "ChangePasswordUserController",
    meta: {
      isPublic: true,
    }
  },
  {
    path: "/sendNotificationChangePassword",
    name: "SendNotificationChangePassword",
    meta: {
      isPublic: true,
    }
  },
  {
    path: "/welcomeHome",
    name: "WelcomeHome",
    meta: {
      isPublic: true,
    }
  },
  {
    path: "/ovis",
    name: "Ovis",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/certificateTemplateEditor",
    name: "CertificateTemplateEditor",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/blogArticles",
    name: "BlogArticles",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/crearConvocatoria",
    name: "Announcement",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verTodasLasNoticias",
    name: "ViewAllNews",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verTodasLasConvocatoriasAbiertas",
    name: "ViewAllAnnouncementOpen",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/detalleConvocatoriasCerradas",
    name: "DetailAnnouncementClosed",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/crearRecomendado",
    name: "CreateRecommended",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/detalleConvocatoria/:id",
    name: "DetailAnnouncement",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verTodasLasConvocatoriasCerradas",
    name: "ViewAllAnnouncementClosed",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/evaluarGrupoDeInvestigacion/:id",
    name: "EvaluateResearchGroup",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/verTodosLosGruposDeInvestigacion",
    name: "ViewAllResearchGroups",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/detalleMisConvocatorias/:id",
    name: "DetailAnnouncementStudent",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/administradorConvocatorias",
    name: "AdminAnnouncement",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/idiProfesorInvestigador",
    name: "IdiResearchProfessor",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/solicitudCreaciondeGrupo",
    name: "GroupCreation",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/misConvocatorias",
    name: "ViewAllMyAnnouncement",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/detalleNoticia/:id",
    name: "DetailNews",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/idiUniversidad",
    name: "IdiUniversity",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/crearGrupoDeInvestigacion",
    name: "CreateResearchGroup",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/plantaAcademica",
    name: "AcademicPlant",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/plantaAcademicaInvestigadores",
    name: "AcademicPlantResearchers",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/plantaAcademicaSolicitudInvestigadores",
    name: "AcademicPlantResearchersRequests",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/gruposDeInvestigacionUniversidad",
    name: "UniversityResearchGroups",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/solicitudesDeCreacionGrupos",
    name: "GroupCreationRequests",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/solicitudesDeCierre",
    name: "ClosureRequests",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/editarGrupoDeInvestigacion",
    name: "EditResearchGroup",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/aplicantesGrupoDeInvestigacion",
    name: "ResearchGroupApplicants",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/detalleConvocatoriasProfesorInvestigador/:id",
    name: "DetailAnnouncementResearchProfessor",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/postularUnGrupoDeInvestigacion/:id",
    name: "ApplyResearchGroup",
    props: true,
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/convocatoriasExternas",
    name: "AnnouncementExternal",
    meta: {
      isPublic: true,
    }
  },
  {
    path: "/detalleConvocatoriasExternas/:id",
    name: "DetailAnnouncementExternal",
    meta: {
      isPublic: true,
    }
  },
  {
    path: "/postularUnGrupoDeInvestigacionExterno/:id",
    name: "ApplyExternalResearchGroup",
    meta: {
      isPublic: true,
    }
  },
  {
    path: "/idiAdministrador",
    name: "IdiAdministator",
    meta: {
      isPublic: false,
      isAdmin: false
    }
  },
  {
    path: "/home2d",
    name: "Home2d",
    meta: {
      isPublic: false,
      isAdmin: false,
      noFooter: true
    }
  },
  {
    path: "/ovas/estudiantes/:gameType/:moduleName/:gameId",
    name: "Ovas",
    meta: {
      isPublic: false,
      isAdmin: false,
    }
  },
  {
    path: "/ovas/profesores",
    name: "OvasTeachers",
    meta: {
      isPublic: false,
      isAdmin: false,
    }
  },
  {
    path: "/entretenimiento/juegoDeHabilidades",
    name: "SkillGame",
    meta: {
      isPublic: false,
      isAdmin: false,
    }
  },
  {
    path: "/visualizadorDeOvis/:oviId",
    name: "OvisViewer",
    meta: {
      isPublic: false,
      isAdmin: false,
    }
  },
  {
    path: "/sinInstituciones",
    name: "NotInstitutions",
    meta: {
      isPublic: true,
    }
  },
];

const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`@/views/${r.name}/${r.name}`)
  };
});

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  const auth = store.getters.getAuth;
  const token = store.getters.getToken;
  if (to.matched.some(record => !record.meta.isPublic)) {
    if (!auth || !token) {
      next({ path: "/login" });
    }
    next();
  }

  next();
});

export default router;
