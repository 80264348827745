import { render, staticRenderFns } from "./ChangeRoleModal.html?vue&type=template&id=c39f862c&scoped=true&"
import script from "./ChangeRoleModal.vue?vue&type=script&lang=js&"
export * from "./ChangeRoleModal.vue?vue&type=script&lang=js&"
import style0 from "./ChangeRoleModal.scss?vue&type=style&index=0&id=c39f862c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c39f862c",
  null
  
)

export default component.exports