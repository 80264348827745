<template src="./ArrobaMedellinMute.html"></template>

<script>
import { songs } from "@/utils/constants";
export default {
  name: "arroba-medellin-mute",
  data() {
    return {
      stateButton: "button-mute-off",
      songs
    };
  },
  mounted() {
    for (const property in this.songs) {
      this.muteMe(this.songs[property]);
    }
  },
  methods: {
    mutePage() {
      if (this.stateButton === "button-mute-on") {
        for (const property in this.songs) {
          this.muteMe(this.songs[property]);
        }
        this.stateButton = "button-mute-off";
      } else {
        for (const property in this.songs) {
          this.songMe(this.songs[property]);
        }
        this.stateButton = "button-mute-on";
      }
    },
    async muteMe(elem) {
      elem.muted = true;
      elem.pause();
      await this.$store.dispatch('fetchMutePage', true);
    },
    async songMe(elem) {
      elem.muted = false;
      await this.$store.dispatch('fetchMutePage', false);
    }
  }
};
</script>

<style lang="scss" scoped src="./ArrobaMedellinMute.scss">

</style>
