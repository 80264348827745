import axios from 'axios';
import store from '@/store';
import configService from "../configs/config.js";
import loginService from "../services/Login";

const nodeInstance = axios.create({
    baseURL: configService.apiNodeUrl,
});

const phpInstance = axios.create({
    baseURL: configService.apiUrl
});

let numLoadings = 0;
const requestHandler = (request) => {
    nodeInstance.defaults.headers.common['Authorization'] = `Bearer ${store.getters.getJwt}`;
    numLoadings++;
    store.dispatch('fetchLoading', true);
    return request;
}

const phpRequestHandler = (request) => {
    phpInstance.defaults.headers.common['Authorization'] = `Bearer ${store.getters.getToken}`;
    numLoadings++;
    store.dispatch('fetchLoading', true);
    return request;
};

const responseHandler = (response) => {
    if ((--numLoadings) === 0) {
        store.dispatch('fetchLoading', false);
    }
    if (response.data) {
        return response.data
    }
    return response;
};
const errorHandler = async (error) => {
    if (error.response.status === 401) {
        store.dispatch("fetchAuth", false);
        store.dispatch("fetchToken", null);
        store.dispatch("fetchJwt", null);
        store.dispatch("fetchUser", null);
        try {
            await loginService.logout();
        } catch (error) {
            console.error("Error on logoff monitor", error);
        }
    }
    if (!(--numLoadings)) {
        store.dispatch('fetchLoading', false);
    }

    return Promise.reject(error);
};

nodeInstance.interceptors.request.use(request => requestHandler(request));
nodeInstance.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error),
);

phpInstance.interceptors.request.use(request => phpRequestHandler(request));
phpInstance.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error),
);

export { nodeInstance, phpInstance };
