var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arroba-medellin-calendar"},[_c('b-dropdown',{attrs:{"append-to-body":"","aria-role":"menu","scrollable":"","max-height":"200","trap-focus":""},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',{staticClass:"badge navbar-item__badage"},[_vm._v("1")]),_c('a',{attrs:{"role":"button"}},[_c('agenda-icon',{staticClass:"navbar-item__navbar-icon"})],1)]},proxy:true}])},[_c('div',{staticClass:"arroba-medellin-calendar-items"},[_c('b-dropdown-item',{attrs:{"custom":"","aria-role":"listitem"}},[_c('vue-scheduler',{attrs:{"min-date":null,"max-date":null,"labels":{
            today: 'Hoy',
            back: 'Atrás',
            next: 'Siguiente',
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            all_day: 'Todo el día'
          },"time-range":[10,20],"available-views":['day'],"initial-date":new Date(),"initial-view":"day","use12":"","show-time-marker":false,"show-today-button":false,"eventDisplay":"eventDisplay","event-dialog-config":_vm.dialogConfig}})],1),_c('b-dropdown-item',[_c('router-link',{attrs:{"to":"/Calendar"}},[_vm._v("Ver más")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }