import { nodeInstance } from "@/plugins/axios";

const jobsService = {};

jobsService.getJobs = () => {
  return nodeInstance.get('/api/owner/jobOffer');
}
jobsService.getJobsStudent = () => {
  return nodeInstance.get('/api/applicant/jobOffer');
}
jobsService.getSpecificJobsProcess = (jobOfferId) => {
  return nodeInstance.get(`/api/jobOffer/${jobOfferId}/application`);
}
jobsService.getJobApplicationPhases = () => {
  return nodeInstance.get(`/api/jobOfferApplicationPhase`);
}
jobsService.getSpecificJob = (id) => {
  return nodeInstance.get(`/api/jobOffer/${id}`);
}
jobsService.getApplicantsJob = (jobOfferId, userId, jobOfferPhaseId) => {
  return nodeInstance.get(`/api/jobOffer/${jobOfferId}/applicants`, { params: { jobOfferPhaseId } });
}
jobsService.getJobOfferSearch = (word) => {
  return nodeInstance.get(`/api/jobOffer/search`, { params: { word } });
}
jobsService.getAllCurriculum = () => {
  return nodeInstance.get(`/api/curriculum`);
}
jobsService.getCurriculum = (id) => {
  return nodeInstance.get(`/api/curriculum/${id}`);
}
jobsService.createdCurriculum = (cvData) => {
  return nodeInstance.post(`/api/curriculum`, cvData);
}

jobsService.updateCurriculum = (cvData) => {
  return nodeInstance.patch(`/api/curriculum/${cvData.id}`, cvData.data);
}
jobsService.createCurriculum= (cvData) => {
  return nodeInstance.post(`/api/curriculum`, { cvData });
}
jobsService.deleteCurriculum= (id) => {
  return nodeInstance.delete(`/api/curriculum/${id}`);
}
jobsService.getOfferRecommended = (jobOfferId) => {
  return nodeInstance.get(`/api/jobOffer/${jobOfferId}/recommended`);
}
jobsService.quitJob = (jobOfferApplicationId) => {
  return nodeInstance.patch(`/api/jobOfferApplication/${jobOfferApplicationId}/quit`);
}
jobsService.advanceJobApplication = (jobOfferApplicationId) => {
  return nodeInstance.patch(`/api/jobOfferApplication/${jobOfferApplicationId}/advance`);
}
jobsService.rejectJobApplication = (jobOfferApplicationId, feedback) => {
  return nodeInstance.patch(`/api/jobOfferApplication/${jobOfferApplicationId}/reject`, { feedback });
}
jobsService.createJob = async (data) => {
  return (await nodeInstance.post(`/api/jobOffer`, { ...data }));
}
jobsService.applyJob = (jobOfferId) => {
  return nodeInstance.post(`/api/jobOffer/${jobOfferId}/apply`);
}
jobsService.updateJob = (data, jobId) => {
  return nodeInstance.put(`/api/jobOffer/${jobId}`, { ...data });
}
jobsService.deleteJob = (jobOfferId) => {
  return nodeInstance.delete(`/api/jobOffer/${jobOfferId}`);
}
jobsService.sendInvitation = (jobOfferId, userId) => {
  return nodeInstance.post(`/api/jobOffer/${jobOfferId}/invitation`, null, { params: { userId } });
}

jobsService.createMassiveJobs = async data => nodeInstance.post(`/api/jobOffers`, data);

export default jobsService;
