// Packages
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueMoment from "vue-moment";
import moment from "moment";
import Buefy from 'buefy';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueScheduler from 'v-calendar-scheduler';
import VueChatScroll from 'vue-chat-scroll'
import VueTour from 'vue-tour'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import '@ckeditor/ckeditor5-build-classic/build/translations/es.js';
import VueGrapesjsComponent from 'vue-grapesjs-component';

// App
import App from './App.vue';
import router from './router';
import store from './store';
import { stringSlicer } from "./filters/stringSlicer";
import VueClipboard from 'vue-clipboard2'
// Styles
import 'swiper/css/swiper.css';
import 'v-calendar-scheduler/lib/main.css';
require('@/assets/scss/bulma.scss')
require('@/assets/scss/base.scss')
require('@/assets/scss/breakpoints.scss')
require('@/assets/scss/swiper.scss')
require('@/assets/scss/tutorial.scss')
import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_ID_GOOGLE_ANALITYCS,
  router
});

// Vue
moment.locale("es");
Vue.filter('stringSlicer', stringSlicer);
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false
Vue.use(Buefy);
Vue.use(VueClipboard)
Vue.use(Vuelidate);
Vue.use(VueGrapesjsComponent);
Vue.use(VueMoment, { moment });
Vue.use(VueScheduler, {
  locale: 'es',
  minDate: null,
  maxDate: null,
  labels: {
    today: 'Hoy',
    back: 'Atrás',
    next: 'Siguiente',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    all_day: 'Todo el día'
  },
  timeRange: [8, 20],
  availableViews: ['month'],
  initialDate: new Date(),
  initialView: 'week',
  use12: true,
  showTimeMarker: true,
  showTodayButton: false,
  eventDisplay: null,
});
Vue.use(VueChatScroll);
Vue.use(VueTour)
Vue.use(CKEditor);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
