<template>
  <div id="app">
    <arroba-medellin-arrobita-loading />
    <div v-if="isPublic && setting">
      <NoLogin />
    </div>
    <div v-else-if="isPublic">
      <router-view />
    </div>
    <div v-else>
      <Dashboard />
    </div>
  </div>
</template>

<script>
import Dashboard from "@/layout/Dashboard/Dashboard";
import NoLogin from "@/layout/NoLogin/NoLogin";

import { nodeInstance, phpInstance } from "@/plugins/axios";

import ArrobaMedellinArrobitaLoading from "@/components/ArrobaMedellinArrobitaLoading/ArrobaMedellinArrobitaLoading";
export default {
  name: "app",
  components: {
    Dashboard,
    NoLogin,
    ArrobaMedellinArrobitaLoading
  },
  created() {
    nodeInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$store.getters.getJwt}`;
    phpInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$store.getters.getToken}`;
  },
  computed: {
    isPublic() {
      return this.$route.meta?.isPublic ?? false;
    },
    setting() {
      return this.$route.meta?.authSetting ?? false;
    },
  },
};
</script>
<style>

</style>
