import { nodeInstance } from "@/plugins/axios";
import { db } from '@/plugins/firebase';
const notificationsDb = db.collection("notifications");

const notificationsService = {};

notificationsService.getNotifications = () => nodeInstance
  .get('/api/notification/types/summary');

notificationsService.getAllNotifications = () => nodeInstance.get('/api/notification/summary');

notificationsService.getTypeNotifications = () => nodeInstance.get('/api/notification/types');

notificationsService.removeNotification = id => {
  notificationsDb.doc(id).delete();
}

notificationsService.shareAnnouncement = obj => {
  return nodeInstance.post(`/api/notification/followers`, obj);
};

export default notificationsService;