<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65.176"
    height="65.177"
    viewBox="0 0 65.176 65.177"
    v-on="$listeners"
  >
    <defs>
      <clipPath id="prefix__af">
        <path
          data-name="Trazado 2385"
          d="M21.827.148a8.4 8.4 0 0 1 8.4 8.4V11.9a1.679 1.679 0 1 1-3.358 0V8.543a5.037 5.037 0 0 0-5.037-5.037H8.4a5.037 5.037 0 0 0-5.042 5.037V11.9A1.679 1.679 0 0 1 0 11.9V8.543A8.4 8.4 0 0 1 8.4.148h13.427zm10.127 1.478A1.679 1.679 0 0 1 34 .42a8.4 8.4 0 0 1 6.3 8.123V11.9a1.679 1.679 0 1 1-3.358 0V8.544a5.037 5.037 0 0 0-3.782-4.873 1.679 1.679 0 0 1-1.206-2.045zM15.111-20a8.4 8.4 0 0 1 8.4 8.4 8.4 8.4 0 0 1-8.4 8.4 8.4 8.4 0 0 1-8.4-8.4 8.4 8.4 0 0 1 8.4-8.4zm10.127 1.481a1.679 1.679 0 0 1 2.043-1.21 8.4 8.4 0 0 1 6.312 8.129 8.4 8.4 0 0 1-6.313 8.133 1.679 1.679 0 0 1-2.043-1.21 1.679 1.679 0 0 1 1.21-2.043 5.037 5.037 0 0 0 3.788-4.88 5.037 5.037 0 0 0-3.788-4.88 1.679 1.679 0 0 1-1.209-2.039zm-10.127 1.877a5.037 5.037 0 0 0-5.037 5.042 5.037 5.037 0 0 0 5.037 5.037 5.037 5.037 0 0 0 5.037-5.037 5.037 5.037 0 0 0-5.037-5.042z"
          transform="translate(0 20)"
          fill="#ae3e97"
        ></path>
      </clipPath>
      <clipPath id="prefix__bf">
        <path
          data-name="Trazado 2384"
          d="M-1395 1258.909h3092.729V-44H-1395z"
          transform="translate(1395 44)"
          fill="#ae3e97"
        ></path>
      </clipPath>
      <clipPath id="prefix__cf">
        <path
          data-name="Rectángulo 1353"
          transform="translate(.395 -.025)"
          fill="#ae3e97"
          d="M0 0h58v52H0z"
        ></path>
      </clipPath>
      <clipPath id="prefix__df">
        <path
          data-name="Trazado 2383"
          d="M0 13.58h40.3V-20H0z"
          transform="translate(0 20)"
          fill="#ae3e97"
        ></path>
      </clipPath>
    </defs>
    <path
      data-name="Trazado 149"
      d="M32.588 65.177a32.588 32.588 0 0 0 32.588-32.588A32.588 32.588 0 0 0 32.588 0 32.588 32.588 0 0 0 0 32.588a32.588 32.588 0 0 0 32.588 32.589z"
      fill="#fff"
      fill-rule="evenodd"
    ></path>
    <g data-name="Grupo 2479">
      <g
        data-name="Grupo 2478"
        transform="translate(13 16)"
        clip-path="url(#prefix__af)"
      >
        <g
          data-name="Grupo 2477"
          transform="translate(-2342.213 -40.296)"
          clip-path="url(#prefix__bf)"
        >
          <g data-name="Grupo 2476">
            <g
              data-name="Grupo 2475"
              transform="translate(2333.818 31.901)"
              clip-path="url(#prefix__cf)"
            >
              <g
                data-name="Grupo 2474"
                transform="translate(8.395 8.395)"
                clip-path="url(#prefix__df)"
              >
                <path
                  data-name="Trazado 2382"
                  d="M-8.395-8.395h57.086v50.37H-8.395z"
                  fill="#ae3e97"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'follows'
}
</script>