<template src="./ArrobaMedellinAppBar.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import AgendaIcon from "@/components/Icons/Agenda";
import AgendaIconActive from "@/components/Icons/AgendaActive";
import ArrobaMedellinCalendar from "@/components/ArrobaMedellinCalendar/ArrobaMedellinCalendar";
import ArrobaMedellinFollows from "@/components/ArrobaMedellinFollows/ArrobaMedellinFollows";
import ArrobaMedellinMute from "@/components/ArrobaMedellinMute/ArrobaMedellinMute";
import { districtInfo } from "@/utils/constants";
import calendarService from "../../services/Calendar/Index";
import moment from "moment";
import { roleName, songs } from "../../utils/constants";
import Notifications from "@/components/Notifications/Notifications";
import Chat from "@/components/Chat/Chat";
import SideMenu from "@/components/SideMenu/SideMenu";

export default {
  name: "arroba-medellin-app-bar",
  components: {
    ArrobaMedellinLogo,
    AgendaIcon,
    AgendaIconActive,
    ArrobaMedellinCalendar,
    ArrobaMedellinFollows,
    ArrobaMedellinMute,
    Notifications,
    Chat,
    SideMenu,
  },
  data() {
    return {
      songs,
      userInfo: "",
      userRol: null,
      follows: [],
      take: 20,
      skip: 0,
      showNavMenu: false,
      isScrollable: false,
      maxHeight: 200,
      searchFollow: "",
      users: [],
      calendarSelected: false,
      closeOnclick: false,
      currentMenu: { icon: "account-group", text: "People" },
      events: [],
      dialogConfig: {
        createButtonLabel: "Crear",
        title: "Crear Evento",
        fields: [
          {
            name: "name",
            label: "Nombre del evento",
          },
          {
            name: "comments",
            type: "textarea",
            label: "Comentarios",
          },
        ],
      },
      initialView: "day",
      dayOld: moment(),
      unreadMessages: null,
      roleName,
      role: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    validateRol() {
      if (this.user) {
        return this.user.role.name !== roleName.ADMINISTRATOR;
      }
      return false;
    },
    getDistrictInfo() {
      return (district) => districtInfo.find((item) => item.name == district);
    },
    roleValidation() {
      return ["Student", "Teacher", "TeacherInvestigator"].some(
        (a) => a === this.role
      );
    },
  },
  created() {
    this.role = this.user.role.name;
  },
  mounted() {
    if (this.user) {
      this.fetchFollows({ take: this.take, skip: this.skip });
      this.getEventCalendar(this.dayOld);
    }
  },
  methods: {
    ...mapActions({
      fetchFollows: "fetchFollows",
    }),
    togleMenu(toggleNav) {
      this.showNavMenu = toggleNav;
      this.songs.CLOSE.play();
    },
    redirectTo(route) {
      this.$router.push({ path: `${route}` }).catch(() => {});
    },
    activeCalendar() {
      this.calendarSelected = !this.calendarSelected;
      if (screen.width < 480) {
        this.redirectTo("Calendar");
      }
    },
    async getEventCalendar(now) {
      const startDate = moment(now).startOf(this.initialView).toDate();
      this.events = await calendarService.getEventCalendarUser(startDate, null);
    },
    async eventCreated(event) {
      if (event.endTime < event.startTime) {
        this.$buefy.toast.open({
          duration: 8000,
          message: "La hora final no puede ser menor a la inicial",
          position: "is-top",
          type: "is-warning",
        });
      } else {
        this.createEvent(event);
      }
    },
    async createEvent(event) {
      try {
        await calendarService.createCalendarEvent(event);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Evento creado",
          position: "is-top",
          type: "is-primary",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async dayChanged(newDay) {
      if (this.user) {
        await this.getEventCalendar(moment(newDay).startOf("day").toDate());
      }
    },
    eventDisplay: (event) => `${event.name} - ${event.comments}`,
  },
};
</script>

<style lang="scss" scoped src="./ArrobaMedellinAppBar.scss"></style>
