<template src="./ChangeRoleModal.html"></template>

<script>
import { mapGetters } from "vuex";
import loginService from "@/services/Login";
import { nodeInstance, phpInstance } from "@/plugins/axios";

export default {
  name: "change-role-modal",
  computed: {
    ...mapGetters({
      userRole: "getUserRole",
    }),
    nameOfRol() {
      return (nameRole) => {
        switch (nameRole) {
          case "Student":
            return "Estudiante";
          case "Management":
            return "Management";
          case "Administrator":
            return "Administrador";
          case "University":
            return "Universidad";
          case "AdministratorCall":
            return "Administrador de convocatorias";
          case "ManagementIES":
            return "ManagementIES";
          case "Teacher":
            return "Docente";
          case "Entrepreneur":
            return "Empresario";
          case "Invited":
            return "Invitado";
          case "TeacherInvestigator":
            return "Docente investigador";
          default:
            return "";
        }
      };
    },
  },
  props: {
    openChangeRoleModal: { type: Boolean, default: false },
  },
  methods: {
    closeModal() {
      this.$emit("closeTheChangeRoleModal");
    },
    async changeRole(roleId) {
      try {
        nodeInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.$store.getters.getTokenControllerNode}`;
        phpInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.$store.getters.getTokenControllerPhp}`;
        const infoUser = await loginService.getUserAuthByRol({
          userId: JSON.stringify(roleId),
          token: this.$store.getters.getTokenControllerPhp,
        });
        nodeInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${infoUser.tokenNode}`;
        phpInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${infoUser.tokenPhp}`;
        this.$store.dispatch("fetchUser", infoUser.user);
        this.$store.dispatch("fetchJwt", infoUser.tokenNode);
        this.$store.dispatch("fetchToken", infoUser.tokenPhp);
        if (infoUser.user.role.name === "Administrator") {
          await this.$router.push("/AdminPanelAcademic");
          location.reload();
        } else if (infoUser.user.role.name === "AdministratorCall") {
          await this.$router.push("/administradorConvocatorias");
          location.reload();
        } else {
          this.$router.name;
          if (this.$router.name === "Home3D") {
            location.reload();
          } else {
            this.$router.push("/");
            location.reload();
          }
        }
        this.closeModal();
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./ChangeRoleModal.scss">
</style>