<template src="./SideMenu.html"></template>

<script>
import { mapActions, mapGetters } from "vuex";
import { roleName } from "@/utils/constants";
import loginService from "@/services/Login";
import ChangeRoleModal from "../ChangeRoleModal/ChangeRoleModal";

export default {
  name: "SideMenu",
  components: { ChangeRoleModal },
  props: {
    open: { type: Boolean },
  },
  data() {
    return {
      overlay: false,
      selectedRole: null,
      roleName,
      openChangeRoleModal: false,
    };
  },
  mounted() {
    this.selectedRole = this.user ? this.user.role.name : null;
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      userRole: "getUserRole",
    }),
    urlPhoto() {
      return this.$store.getters.getPhoto;
    },
    nameOfRol() {
      return (nameRole) => {
        switch (nameRole) {
          case "Student":
            return "Estudiante";
          case "Management":
            return "Management";
          case "Administrator":
            return "Administrador";
          case "University":
            return "Universidad";
          case "AdministratorCall":
            return "Administrador de convocatorias";
          case "ManagementIES":
            return "ManagementIES";
          case "Teacher":
            return "Docente";
          case "Entrepreneur":
            return "Empresario";
          case "Invited":
            return "Invitado";
          case "TeacherInvestigator":
            return "Docente investigador";
          default:
            return "";
        }
      };
    },
  },
  methods: {
    ...mapActions({
      fetchToken: "fetchToken",
      fetchJwt: "fetchJwt",
      fetchAuth: "fetchAuth",
    }),
    async logout() {
      try {
        this.fetchAuth(false);
        this.fetchToken(null);
        this.fetchJwt(null);
        this.$store.dispatch("fetchUser", null);
        try {
          await loginService.logout();
        } catch (error) {
          console.error("Error on logoff monitor", error);
        }
        this.$router.push("/login");
        const WebGL = document.getElementByTagName("canvas");
        const body = document.getElementByTagName("body");
        body.parentNode.removeChild(WebGL);
      } catch (error) {
        console.error(error.response.data.message);
      }
    },
    openTheChangeRoleModal() {
      this.openChangeRoleModal = true;
    },
    closeTheChangeRoleModal() {
      this.openChangeRoleModal = false;
    },
    closeSideBar() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped src="./SideMenu.scss"></style>