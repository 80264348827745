<template src="./ArrobaMedellinFollows.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import FollowsIcon from "@/components/Icons/Follows";
import userService from "@/services/User";
import { PROFILE_IMAGEN } from "@/utils/constants";

export default {
  name: "arroba-medellin-follows",
  data() {
    return {
      follows: [],
      take: 20,
      skip: 0,
      userInfo: null,
      users: null,
      searchFollow: "",
      isLoading: false,
      PROFILE_IMAGEN,
    };
  },
  components: { FollowsIcon },
  computed: {
    ...mapGetters({
      user: "getUser",
      follow: "getFollows",
    }),
  },
  methods: {
    ...mapActions({
      fetchFollows: "fetchFollows",
    }),
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
    redirectProfileUser(path, follow) {
      this.$router.push({ name: path, params: { id: follow } });
    },
    async searchModerator() {
      if (this.searchFollow.length >= 3) {
        setTimeout(async () => {
          try {
            this.isLoading = true;
            this.users = await userService.searchUser(this.searchFollow);
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "La solicitud buscar un seguidor se realizo con exito!",
              type: "is-success",
            });
            this.isLoading = false;
          } catch (error) {
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "Error enviado solicitud!",
              type: "is-error",
            });
            this.isLoading = false;
          }
        }, 300);
      }
    },
  },
  watch: {
    user: function (value) {
      this.userInfo = value;
      this.fetchFollows({ take: this.take, skip: this.skip });
    },
    follow: function (value) {
      this.follows = value;
    },
  },
};
</script>

<style lang="scss" scoped src="./ArrobaMedellinFollows.scss"></style>
