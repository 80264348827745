import { nodeInstance, phpInstance } from "@/plugins/axios";
import { storage } from "../../plugins/firebase";
import store from "@/store";

const profileService = {};

profileService.getUserData = () => {
  return phpInstance.get(`/user/profile/getProfile`);
};

profileService.getUserDataNode = () => {
  return nodeInstance.post(`/api/user/profile/getProfile`);
};

profileService.getUserDataId = id => {
  return phpInstance.get(`/user/profile/getProfileById/${id}`);
};

profileService.getUserInfo = () => {
  return nodeInstance.get('/api/user');
}

profileService.updateUserInfo = user => {
  user.token = store.getters.getToken;
  return nodeInstance.patch('/api/user', user);
}

profileService.editProfile = user => {
  return phpInstance.post(`/user/profile/updateProfile`, user);
};

profileService.getSocialMedias = idUser => {
  const params = {
    idUser
  }
  return nodeInstance.get(`/api/socialMedias`, { params });
};

profileService.postSocialMedias = obj => {
  return nodeInstance.post(`/api/socialMedia`, obj);
};

profileService.scoreStars = obj => {
  return nodeInstance.post(`/api/stars`, obj);
};

profileService.getAverageScoreStars = (id) => {
  const params = {
    typePost: 'teacher',
    post: id
  }
  return nodeInstance.get(`/api/stars/average`, {params});
};
profileService.getRecommendationTeacher = userTeacherId => {
  return nodeInstance.get(`/api/recommendationTeacher/${userTeacherId}`);
};
profileService.setRecommendationTeacher = userTeacherRecommendId => {
  return nodeInstance.post(`/api/recommendationTeacher`, {userTeacherRecommendId});
};

profileService.getCurriculum = idUser => {
  const params = {
    idUser
  };
  return nodeInstance.get(`/api/listCurriculum`, { params });
};

profileService.putFile = (folder, subFolder, name, file) => {
  const route = !subFolder ? `${folder}` : `${folder}/${subFolder}`;
  return storage
    .child(`${route}/${name}`)
    .put(file)
    .then(async snapshot => {
      return await snapshot.ref.getDownloadURL();
    });
};

export default profileService;
