import { PROFILE_IMAGEN } from "@/utils/constants"
import profileService from '@/services/Profile';
import router from "@/router";

let avatarElements = []
export default {
  state: {
    user: null,
    token: null,
    jwt: null,
    tokenControllerNode: null,
    tokenControllerPhp: null,
    auth: null,
    userEmail: null,
    userInfo: {},
    avatarItems: [],
    userRole: null,
    initialHome: true,
  },
  getters: {
    getUser: state => state.user,
    getUserRole: state => state.userRole,
    getUserId: state => state.user.id,
    getToken: state => state.token,
    getUserEmail: state => state.userEmail,
    getJwt: state => state.jwt,
    getTokenControllerNode: state => state.tokenControllerNode,
    getTokenControllerPhp: state => state.tokenControllerPhp,
    getAuth: state => state.auth,
    avatarItems: state => state.avatarItems,
    getPhoto: state => state.user && state.user.photo ? state.user.photo : PROFILE_IMAGEN,
    getInitialHome: state => state.initialHome,
  },
  mutations: {
    setUser(state, value) {
      state.user = value;
    },
    setUserRole(state, value) {
      state.userRole = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value
    },
    setToken(state, value) {
      state.token = value
    },
    setJwt(state, value) {
      state.jwt = value
    },
    setTokenControllerNode(state, value) {
      state.tokenControllerNode = value
    },
    setTokenControllerPhp(state, value) {
      state.tokenControllerPhp = value
    },
    setAuth(state, value) {
      state.auth = value
    },
    setUserEmail(state, value) {
      state.userEmail = value;
    },
    setAvatarLook(state, value) {
      state.avatarItems = value;
    },
    setInitialHome(state, value) {
      state.initialHome = value;
    },
  },
  actions: {
    setAvatarLook({ commit }, element) {
      avatarElements = avatarElements.filter(item => item.avatarSubcategory.id !== element.avatarSubcategory.id)
      avatarElements.push(element);

      commit('setAvatarLook', avatarElements)
    },
    setAvatarElements({ commit }, elements) {
      avatarElements = elements

      commit('setAvatarLook', avatarElements)
    },
    fetchUser({ commit }, user) {
      commit('setUser', user);
    },
    fetchUserRole({ commit }, role) {
      commit('setUserRole', role);
    },
    async fetchUserInfo({ commit }) {
      try {
        const data = await profileService.getUserInfo();
        commit('setUser', data);
      } catch (e) {
        console.error('Error', e);
      }
    },
    fetchToken({ commit }, payload) {
      try {
        commit('setToken', payload);
      } catch (e) {
        console.error('Error', e);
      }
    },
    fetchJwt({ commit }, payload) {
      try {
        commit('setJwt', payload);
      } catch (e) {
        console.error('Error', e);
      }
    },
    fetchTokenControllerNode({ commit }, payload) {
      try {
        commit('setTokenControllerNode', payload);
      } catch (e) {
        console.error('Error', e);
      }
    },
    fetchTokenControllerPhp({ commit }, payload) {
      try {
        commit('setTokenControllerPhp', payload);
      } catch (e) {
        console.error('Error', e);
      }
    },
    fetchAuth({ commit }, payload) {
      try {
        commit('setAuth', payload);
      } catch (e) {
        console.error('Error', e);
      }
    },
    fetchUserEmail({ commit }, payload) {
      try {
        commit('setUserEmail', payload);
      } catch (e) {
        console.error('Error', e);
      }
    },
    async fetchInitialHome({ commit }, initialHome) {
      if (!initialHome) {
        router.push("/home2d").catch(e => console.log(e));
      } else {
        router.push("/").catch(e => console.log(e));
      }
      commit('setInitialHome', initialHome);
    },
  }
};
